var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.nextSection ? _c('div', [_c('ContentRecomendation', {
    attrs: {
      "sectionTitleCallback": _vm.sectionTitleCallback,
      "sectionDescritionCallBack": _vm.sectionDescritionCallBack
    },
    on: {
      "next-clicked": _vm.handleNextClicked
    }
  })], 1) : _c('div', [_c('ContentSelection', {
    attrs: {
      "sectionTitle": _vm.sectionTitle,
      "description": _vm.description
    },
    on: {
      "back-clicked": _vm.handelBackClicked
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }