<template>
    <div>
      <div class="bg-white p-4 text-gray-500  my-4 rounded-lg shadow-sm">
      <span class="py-3 text-gray-800">
        Content Recommendation <i class="fa fa-angle-right" aria-hidden="true"></i> Edit
        <h5 class="mt-1 text-2xl">Edit Content Recommendation</h5>
      </span>
    </div>
      <div class="mt-4 bg-white rounded-lg px-4 py-3 w-full flex justify-start shadow-sm">
        <div class="mr-3 flex justify-start items-center">
          <div class="rounded-full border-custom w-7 h-7 p-1 justify-center text-sm flex font-semibold text-blue-500">
            1
          </div>
          <span class="ml-3 text-blue-500 font-semibold">Content Recommendation Detail</span>
        </div>
        <div class="mr-3 flex justify-start items-center">
          <div class="rounded-full w-7 h-7 border p-1 justify-center text-sm flex">2</div>
          <span class="text-gray-600 ml-3">Content Selection</span>
        </div>
        <span></span>
      </div>
      <div class="mt-4 bg-white px-4 py-3 rounded-lg shadow-sm">
        <div class="flex w-full">
          <div class="w-1/2 flex flex-col">
            <label>Section Title</label>
            <input v-model="sectionTitle" id="section_title" type="text" class="bg-gray-50 p-2 border rounded-lg" />
  
            <label class="mt-3">Description</label>
            <textarea v-model="description" id="description" class="bg-gray-50 p-2 border rounded-lg h-52"></textarea>
          </div>
        </div>
        <div class="flex w-full justify-start mb-3">
          <button @click="handleNextClick" class="bg-blue-500 text-white py-2 px-3 rounded-lg mt-3 mr-2">
            Next
          </button>
          <button class="bg-white border py-2 px-3 rounded-lg mt-3 mr-2" @click="handleCancel">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  

  <script>
  import { mapActions, mapState } from 'vuex';
  import 'flowbite';

  export default {
    props: {
      sectionTitleCallback: {
            type: String,
            default: ''
        },
        sectionDescritionCallBack : {
            type: String,
            default: ''
        }
    },
    data() {
      return {
        itemId: parseInt(this.$route.params.id),
        sectionTitle: '',
        description: '',
      };
    },
    created() {
      this.detailRecomendationTitleDescription(this.itemId)
    },
    computed: {
    ...mapState({
      descriptionAndTitle:(state) =>state.content.descriptionAndTitle, 
      successMessage: (state) => state.content.successMessage,
      errorMessage: (state) => state.content.errorMessage,
      isLoading: (state) => state.content.isLoading,
    }),
  },
    watch: {
      descriptionAndTitle: function() {
      if (!Object.keys(this.descriptionAndTitle).length) return;
      this.getDataDescriptionAndTitle();
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
  },
    methods: {
      ...mapActions('content', ['detailRecomendationTitleDescription']),
      async getDataDescriptionAndTitle() {
      const data = this.descriptionAndTitle;
      data.forEach(element => {
        this.sectionTitleCallback === '' ? this.sectionTitle = element.title : this.sectionTitle = this.sectionTitleCallback
        this.sectionDescritionCallBack === '' ? this.description = element.description : this.description = this.sectionDescritionCallBack
      });
    },

    handleNextClick() {
      this.$emit('next-clicked', {
        sectionTitle: this.sectionTitle,
        description: this.description,
      });
    },
    handleCancel() {
      this.$router.push('/content-recommedation');
    }
    },
  };
  </script>
  