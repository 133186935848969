<template>
    <div>
        <div v-if="!nextSection">
            <ContentRecomendation  
            :sectionTitleCallback="sectionTitleCallback"
            :sectionDescritionCallBack = "sectionDescritionCallBack"
            @next-clicked="handleNextClicked"/>
        </div>
        <div v-else>
            <ContentSelection 
              :sectionTitle="sectionTitle"
              :description="description"
            @back-clicked="handelBackClicked" />
           
        </div>
       
    </div>
</template>
<script>
import 'flowbite';
import ContentSelection from './content-selection.vue'
import ContentRecomendation from './content-edit-recomendation.vue'

export default {
    components: {
        ContentSelection,
        ContentRecomendation
  },
    data() {
        return {
            nextSection:false,
            sectionTitle:'',
            sectionTitleCallback:'',
            sectionDescritionCallBack:'',
            description:''
        };
    },
    methods: {
        handleNextClicked(data) {
         this.nextSection = data
         this.sectionTitle = data.sectionTitle
         this.description = data.description
        },
        handelBackClicked(title,description) {
            this.nextSection = false
            this.sectionTitleCallback = title
            this.sectionDescritionCallBack = description
        }
    }
};
</script>
<style>
.border-custom {
    border: 1px solid #3b82f6;
}
</style>