var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "mt-4 bg-white px-4 py-3 rounded-lg shadow-sm"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "w-1/2 flex flex-col"
  }, [_c('label', [_vm._v("Section Title")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.sectionTitle,
      expression: "sectionTitle"
    }],
    staticClass: "bg-gray-50 p-2 border rounded-lg",
    attrs: {
      "id": "section_title",
      "type": "text"
    },
    domProps: {
      "value": _vm.sectionTitle
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.sectionTitle = $event.target.value;
      }
    }
  }), _c('label', {
    staticClass: "mt-3"
  }, [_vm._v("Description")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.description,
      expression: "description"
    }],
    staticClass: "bg-gray-50 p-2 border rounded-lg h-52",
    attrs: {
      "id": "description"
    },
    domProps: {
      "value": _vm.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.description = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "flex w-full justify-start mb-3"
  }, [_c('button', {
    staticClass: "bg-blue-500 text-white py-2 px-3 rounded-lg mt-3 mr-2",
    on: {
      "click": _vm.handleNextClick
    }
  }, [_vm._v(" Next ")]), _c('button', {
    staticClass: "bg-white border py-2 px-3 rounded-lg mt-3 mr-2",
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("Cancel")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white p-4 text-gray-500 my-4 rounded-lg shadow-sm"
  }, [_c('span', {
    staticClass: "py-3 text-gray-800"
  }, [_vm._v(" Content Recommendation "), _c('i', {
    staticClass: "fa fa-angle-right",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Edit "), _c('h5', {
    staticClass: "mt-1 text-2xl"
  }, [_vm._v("Edit Content Recommendation")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-4 bg-white rounded-lg px-4 py-3 w-full flex justify-start shadow-sm"
  }, [_c('div', {
    staticClass: "mr-3 flex justify-start items-center"
  }, [_c('div', {
    staticClass: "rounded-full border-custom w-7 h-7 p-1 justify-center text-sm flex font-semibold text-blue-500"
  }, [_vm._v(" 1 ")]), _c('span', {
    staticClass: "ml-3 text-blue-500 font-semibold"
  }, [_vm._v("Content Recommendation Detail")])]), _c('div', {
    staticClass: "mr-3 flex justify-start items-center"
  }, [_c('div', {
    staticClass: "rounded-full w-7 h-7 border p-1 justify-center text-sm flex"
  }, [_vm._v("2")]), _c('span', {
    staticClass: "text-gray-600 ml-3"
  }, [_vm._v("Content Selection")])]), _c('span')]);
}]

export { render, staticRenderFns }