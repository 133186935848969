<template>
    <div>
        <div class="bg-white p-4 text-gray-500  my-4 rounded-lg shadow-sm">
      <span class="py-3 text-gray-800">
        Content Recommendation <i class="fa fa-angle-right" aria-hidden="true"></i> Edit
        <h5 class="mt-1 text-2xl">Edit Content Recommendation</h5>
      </span>
    </div>
        <div class="mt-4 bg-white rounded-lg px-4 py-3 w-full flex justify-start shadow-sm">
            <div class="mr-3 flex justify-start items-center">
                <div class="rounded-full w-7 h-7 border p-1 justify-center text-sm flex">1</div>
                <span class="text-gray-600 ml-3">Content Recommendation Detail</span>
            </div>
            <div class="mr-3 flex justify-start items-center">
                <div
                    class="rounded-full border-custom w-7 h-7  p-1 justify-center text-sm flex font-semibold text-blue-500 ">
                    2</div>
                <span class="ml-3 text-blue-500 font-semibold">Content Selection</span>
            </div>
            <span></span>
        </div>
        <div class="relative overflow-x-auto mt-4 mb-4 rounded-sm shadow-sm bg-white p-4 ">
            <div class="flex justify-end mb-4">
                <button @click="showModal = true" class=" p-2 border-custom text-blue-500 rounded-lg">
                    <span>+ Add Content</span>
                </button>
                <ModalAddContent :isModalVisible.sync="showModal"/>
                <button @click="generateData" class="ml-3 p-2 border text-gray-500 rounded-lg">
                    <span>Sync Latest Data</span>
                </button>

            </div>

            <div v-if="isLoading" class="mt-5 flex justify-center">
                <div class="text-center text-dark my-2 w-full bg-white"
                    style="height: 600px; background-color:rgb(204 204 204 / 39%); display: flex; border-radius: 5px; justify-content: center; align-items: center;">
                    <div style="transform: translate(-50%, -50%);">
                        <b-spinner :show='isLoading' class="align-middle" variant="dark" type="grow"
                            label="Loading .."></b-spinner>
                        <span v-if="isLoading">Loading ..</span>
                    </div>
                </div>
            </div>

            <div class=" mb-0 relative rounded-lg " v-else>
                <div class="relative overflow-x-auto shadow-md scrollbar parent-table  sm:rounded-lg " id="style-1">
                    <table
                        class="w-full h-[400px] border-r border-l text-sm text-gray-500 text-left rtl:text-right bg-white dark:text-gray-400 drag-move rounded-lg"
                        v-show="!isLoading" :items="items">
                        <thead>
                            <tr>
                                <th scope="col" class="px-6 py-3"></th>
                                <th scope="col" class="px-6 py-3">No</th>
                                <th scope="col" class="px-6 py-3">CONTENT TYPE</th>
                                <th scope="col" class="px-6 py-3">CONTENT ID</th>
                                <th scope="col" class="px-6 py-3">CONTENT NAME</th>
                                <th></th>
                            </tr>
                        </thead>

                        <draggable  v-if="items.length > 0" class="cursor-move" element="tbody" :items="items" :move="checkMove"
                            v-bind="dragOptions" :disabled="!enabled" ghost-class="ghost" @start="handleDragStart"
                            @end="handleDragEnd($event)">

                            <tr v-for="(element, index) in items" :key="element.id" :data-item-id="element.item_id">
                                <td scope="col" class="px-6 py-3">
                                    <span class="block">
                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                           <path fill-rule="evenodd" clip-rule="evenodd" d="M0.879883 4.44001C0.879883 4.08654 1.16642 3.80001 1.51988 3.80001H10.4799C10.8333 3.80001 11.1199 4.08654 11.1199 4.44001C11.1199 4.79347 10.8333 5.08001 10.4799 5.08001H1.51988C1.16642 5.08001 0.879883 4.79347 0.879883 4.44001ZM0.879883 7.00001C0.879883 6.64654 1.16642 6.36001 1.51988 6.36001H10.4799C10.8333 6.36001 11.1199 6.64654 11.1199 7.00001C11.1199 7.35347 10.8333 7.64001 10.4799 7.64001H1.51988C1.16642 7.64001 0.879883 7.35347 0.879883 7.00001ZM0.879883 9.56001C0.879883 9.20654 1.16642 8.92001 1.51988 8.92001H10.4799C10.8333 8.92001 11.1199 9.20654 11.1199 9.56001C11.1199 9.91347 10.8333 10.2 10.4799 10.2H1.51988C1.16642 10.2 0.879883 9.91347 0.879883 9.56001Z" fill="#6B7280"/>
                                           <path fill-rule="evenodd" clip-rule="evenodd" d="M5.74981 0.716474C5.9051 0.561184 6.15688 0.561184 6.31217 0.716474L7.43687 1.84118C7.59216 1.99647 7.59216 2.24825 7.43687 2.40354C7.28158 2.55883 7.02981 2.55883 6.87452 2.40354L6.03099 1.56001L5.18746 2.40354C5.03217 2.55883 4.78039 2.55883 4.6251 2.40354C4.46981 2.24825 4.46981 1.99647 4.6251 1.84118L5.74981 0.716474Z" fill="#6B7280"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.31246 13.2835C6.15717 13.4388 5.90539 13.4388 5.7501 13.2835L4.62539 12.1588C4.4701 12.0035 4.4701 11.7518 4.62539 11.5965C4.78068 11.4412 5.03246 11.4412 5.18775 11.5965L6.03128 12.44L6.87481 11.5965C7.0301 11.4412 7.28188 11.4412 7.43717 11.5965C7.59246 11.7518 7.59246 12.0035 7.43717 12.1588L6.31246 13.2835Z" fill="#6B7280"/>
                                        </svg>
                                    </span>
                                </td>

                                <td scope="col" class="px-6 py-3">{{ index + 1 }}</td>
                                <td scope="col" class="px-6 py-3">{{ element.item_type }}</td>
                                <td scope="col" class="px-6 py-3">{{ element.item_id }}</td>
                                <td scope="col" class="px-6 py-3">{{ element.item_name }}</td>
                                <td>
                                    <span class="block cursor-pointer" @click="removeItem(element, index)">
                                        <i class="fa fa-trash text-gray-500 cursor-pointer" aria-hidden="true"
                                            style="font-size:20px;"></i>
                                    </span>
                                </td>
                            </tr>
                        </draggable>
                        <tbody v-else>
                            <tr>
                                <td colspan="6" class="text-center">No items available</td>
                            </tr>
                         </tbody>
                    </table>
                </div>
                <div class="flex justify-start w-full">
                    <button @click="SaveAndUpdate" class="bg-blue-500 px-3 py-2 rounded-lg text-white mt-0 mb-0">Save &
                        Submit</button>
                    <button @click="handleBackClick"
                        class="bg-white px-3 py-2 rounded-lg text-gray-600 mt-0 mb-0 border ml-2">Back</button>
                </div>
            </div>
        </div>
        <Toast v-if="toastVisible" :message="toastMessage" :type="toastType" />
        <ModalDeleteContent :isModalVisibleDelete.sync="showModalDelete"
        :isOpenDelete="showModalDelete" @close="closeModal" @confirm="confirmDelete" />
    </div>

</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";
import ModalAddContent from '../component/ModalAddContent'
import Toast from '../component/Toast';
import { EventBus } from '../../../../src/event-bus';
import ModalDeleteContent from "../component/ModalDeleteContent.vue";
export default {
    components: {
        draggable,
        Toast,
        ModalDeleteContent,
        ModalAddContent
    },
    props: {
        sectionTitle: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    data() {

        return {

            itemId: parseInt(this.$route.params.id),
            contentTypeOptions: ['book', 'magazine', 'newspaper'],
            selectedOption: '',
            itemsFieldName: [],
            isNameSearch: false,
            dragging: false,
            enabled: true,
            resetFetchbtn: false,
            dataTable: [],
            offerIdExists: false,
            optionsSearchFieldName: [],
            optionsFieldName: [],
            moveDrag: [],
            addAllItem: [],
            draggedItem: null,
            readMore: false,
            triggerSort: false,
            isOpen: false,
            isOpenDelete: false,
            toastVisible: false,
            toastMessage: '',
            toastType: 'info',
            listDeleteTable: [],
            showModal: false,
            itemToDelete: null,
            itemIndex: null,
            showModalDelete: false,
        };

    },
    created() {
        this.detailRecomendationId(this.itemId)
    },
    watch: {
        successMessage: function () {
            if (!this.successMessage) return;
            this.$router.push('/content-recommedation');
        },
        errorMessage: function () {
            if (!this.errorMessage) return;
            this.alertMsg(this.errorMessage, 'error');
        },
    },
    computed: {
        ...mapState({
            items: (state) => state.content.items,
            descriptionAndTitle: (state) => state.content.descriptionAndTitle,
            successMessage: (state) => state.content.successMessage,
            errorMessage: (state) => state.content.errorMessage,
            isLoading: (state) => state.content.isLoading,
        }),

        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                //     draggedIndex: null,
                ghostClass: "ghost",
            };
        },
        isModalVisible() {
            return this.isOpen;
        },
        isModalVisibleDelete() {
            return this.showModalDelete
        }

    },

    methods: {
        ...mapActions('content', ['EditRecomendationTitleDescription']),
        ...mapActions("content", ["detailRecomendationId", "EditRecomendationTitleDescription", "allSaveAndSubmit", "generateFetchData"]),
        ...mapMutations('content', ['deleteItem', 'SET_SORT_PRIORITY']),

        handleBackClick() {
            this.$emit('back-clicked', this.sectionTitle, this.description);
        },
        async generateData() {
            await this.generateFetchData(this.itemId)
        },
        checkMove(e) {
            console.log("Future index: " + e.draggedContext.futureIndex);
        },
        async SaveAndUpdate() {

            const payloadAddItem = {
                id: this.itemId,
                items: this.items
            }
            await this.allSaveAndSubmit(payloadAddItem).then(() => {
                EventBus.$emit('show-toast', {
                    message: 'Update has been success',
                    type: 'success',
                });
            })
    
            const payloadEditDestAndTitle = {
                id: this.itemId,
                title: this.sectionTitle,
                description: this.description
            }
            await this.EditRecomendationTitleDescription(payloadEditDestAndTitle)

        },
        handleDragStart(event) {
            const item_id = parseInt(event.item.dataset.itemId, 10);
            this.draggedItem = this.items.find(item => item.item_id === item_id);
        },
        removeItem(data, index) {
            this.itemToDelete = data;
            this.itemIndex = index;
            this.showModalDelete = true;
        },

        closeModal() {
            this.showModalDelete = false
        },
        confirmDelete() {
            if (this.itemToDelete && this.itemIndex !== null) {
                this.deleteItem(this.itemIndex);
                this.closeModal();
            }
        },
        handleDragEnd(event) {
            const newIndex = event.newIndex;
            const item_id = parseInt(event.item.dataset.itemId, 10);
            const datadrag = this.items.find(item => item.item_id === item_id);
            this.SET_SORT_PRIORITY({ datadrag, newIndex });
        },
    }
};
</script>
<style>
.border-custom {
    border: 1px solid #3b82f6;
}

.custom-arrow {
    cursor: pointer;
}

.recomendation_hompage_cms {
    background-color: #fff;
}

.custom-arrow.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
}

.table.custom.drag-move {
    background-color: #ffffff;
}

table tr {
    background: #ffffff;
}

.table.custom.drag-move thead tr th {
    height: 50px;
}

.list-group {
    background-color: #ffffff;
}

.parent-table {
    max-height: 600px;
    overflow-y: auto;
}

.drag-move th,
.drag-move td {
    padding: 8px 12px;
    text-align: left;
    font-size: 15px;
    vertical-align: middle !important;
    border-bottom: 1px solid #ddd;
}

.drag-move th {
    position: sticky;
    top: 0;
    background-color: #c2cfd6;
    z-index: 2;
}

.scrollbar {
    overflow-y: scroll;
    margin-bottom: 25px;
}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
    background-color: #807474;
}

.drag-move thead th {
    border-bottom: 1px solid #c2cfd6;
}

.drag-move tr:first-child th {
    top: -1px;
    border-top: none;
}

.ghost {
    background-color: #F3F4F6 !important;
    opacity: 1 !important;
    color: #807474;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 500ms ease-out;
}
</style>